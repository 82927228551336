import * as React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelect } from 'components';
import { selectMediaAgencies } from 'store/consts';
import { useFormContext, useController } from 'react-hook-form';
import { selectAllClients } from 'store/clients';

const CampaignMediaAgency = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const { control, setValue } = useFormContext();
  const {
    field: { value: clientValue },
  } = useController({
    name: 'client',
    control,
  });

  const {
    field: { value: agencyValue },
  } = useController({
    name: 'mediaAgency',
    control,
  });
  const clients = useAppSelector(selectAllClients);
  const mediaAgency = useAppSelector(selectMediaAgencies);
  const options = React.useMemo(() => {
    return mediaAgency
      ?.map(({ id, name }: { id: string | number; name: string }) => ({
        id,
        name,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [mediaAgency]);

  React.useEffect(() => {
    if (agencyValue === null && clientValue !== null) {
      const agency = clients.find(client => client.id === clientValue);
      setValue('mediaAgency', agency?.mediaAgency);
    }
  }, [clientValue]);

  return (
    <ControlledSelect
      name='mediaAgency'
      label='Agencja'
      options={options}
      {...props}
    />
  );
};

CampaignMediaAgency.displayName = 'Campaign.mediaAgency';

export { CampaignMediaAgency };
