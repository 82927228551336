import { createSelector } from 'reselect';
import { RootState } from 'store';
import adapter from './productExtraFees.adapter';
import { REDUCER_KEY } from './productExtraFees.consts';
import { ProductExtraFeeParams } from './productExtraFees.types';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (
  state: RootState,
  id: number,
): ProductExtraFeeParams | null => selectors.selectById(state, id) || null;

export const selectCurrentAdFormatId = (state: RootState): number =>
  state[REDUCER_KEY].current;

export const selectAllProductExtraFees = createSelector(
  state => selectors.selectAll(state),
  extraFees => extraFees || [],
);

export function selectProductExtraFee(
  state: RootState,
  productId: number,
): ProductExtraFeeParams | undefined {
  const allExtraFees = selectAllProductExtraFees(state);
  return allExtraFees.find(
    ({ product }: ProductExtraFeeParams) => product === productId,
  );
}
