import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './adTypeExtraFees.consts';
import { AdTypeExtraFeeParams } from './adTypeExtraFees.types';
import { selectAdTypeExtraFee } from './adTypeExtraFees.selectors';

export const fetchAllAdTypeExtraFees = createAsyncThunk(
  REDUCER_KEY + '/fetchAll',
  withErrorHandling(async () => {
    const adTypes = await adserver({
      url: '/monthlyextrafees/adtypes/',
      headers: {},
    });
    return adTypes.data;
  }),
);

export const createAdTypeExtraFee = createAsyncThunk(
  REDUCER_KEY + '/createAdTypeExtraFee',
  withErrorHandling(async (values: AdTypeExtraFeeParams) => {
    const response = await adserver({
      url: '/monthlyextrafees/adtypes/',
      method: 'POST',
      data: values,
      headers: {},
    });
    return response.data;
  }),
);

export const updateAdTypeExtraFee = createAsyncThunk(
  REDUCER_KEY + '/update',
  withErrorHandling(async (data: AdTypeExtraFeeParams) => {
    const { id } = data;

    const response = await adserver({
      url: `/monthlyextrafees/adtypes/${id}/`,
      method: 'PATCH',
      data: data,
      headers: {},
    });
    return response.data;
  }),
);

export const removeAdTypeExtraFee = createAsyncThunk(
  REDUCER_KEY + '/removeProductExtraFee',
  withErrorHandling(async (adType: string) => {
    await adserver({
      url: `/monthlyextrafees/adtypes/${adType}/`,
      method: 'DELETE',
      headers: {},
    });
    return adType;
  }),
);

export const removeAllAdTypeExtraFees = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});
