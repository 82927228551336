import { RootState } from 'store';
import adapter from './baskets.adapter';
import { REDUCER_KEY } from './baskets.consts';
import { BasketParams } from './baskets.types';
import { selectCurrentCampaignId } from 'store/campaigns';
import { createSelector } from 'reselect';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (state: RootState, id: number): BasketParams | null =>
  selectors.selectById(state, id) || null;

export function selectBasket(
  state: RootState,
  basketId: number,
): BasketParams | undefined {
  return selectors.selectById(state, basketId) || undefined;
}

export const selectAllBaskets = createSelector(
  state => selectors.selectAll(state),
  baskets => baskets || [],
);

export const selectBasketsOfCurrentCampaign = createSelector(
  selectAllBaskets,
  selectCurrentCampaignId,
  (allBaskets, campaignId) =>
    allBaskets.filter(basket => basket.campaignId === campaignId) || [],
);

export const selectBasketStatusById = (
  state: RootState,
  basketId: number,
): string | undefined => {
  const basket = selectBasket(state, basketId);
  return basket?.status;
};

export const selectBasketTotalBudgetById = (
  state: RootState,
  basketId: number,
): {
  isCommitted: boolean;
  totalAmount: number;
} => {
  const basket = selectBasket(state, basketId);
  return basket?.totalBudget || { isCommitted: false, totalAmount: 0 };
};
