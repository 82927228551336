export const DASHBOARD = '/';
export const LOGIN = '/login';
export const LOGIN_CALLBACK = '/login/callback';

export const CAMPAIGN_LIST = '/campaign';
export const CAMPAIGN_CREATE = '/campaign/create';
export const CAMPAIGN_SHOW = '/campaign/:campaignId(\\d+)';
export const CAMPAIGN_EDIT = CAMPAIGN_SHOW + '/edit';
export const CAMPAIGN_STATISTICS_APPS = CAMPAIGN_SHOW + '/apps';
export const CAMPAIGN_STATISTICS_WEBSITES = CAMPAIGN_SHOW + '/websites';

export const CREATIVE_LIST = CAMPAIGN_SHOW + '/creative';
export const CREATIVE_CREATE = CAMPAIGN_SHOW + '/creative/create';
export const CREATIVE_SHOW = CAMPAIGN_SHOW + '/creative/:creativeId';
export const CREATIVE_EDIT = CAMPAIGN_SHOW + '/creative/:creativeId/edit';
export const CREATIVE_PREVIEW =
  CAMPAIGN_SHOW + '/creative/:creativeId/livepreview';

export const LINEITEM_LIST = CAMPAIGN_SHOW + '/lineitem';
export const LINEITEM_CREATE = CAMPAIGN_SHOW + '/lineitem/create';
export const LINEITEM_SHOW = CAMPAIGN_SHOW + '/lineitem/:lineitemId';
export const LINEITEM_EDIT = CAMPAIGN_SHOW + '/lineitem/:lineitemId/edit';

export const ORDER_LIST = '/orders';

export const CLIENT_LIST = '/client';
export const CLIENT_CREATE = '/client/create';
export const CLIENT_SHOW = '/client/:clientId(\\d+)';
export const CLIENT_EDIT = '/client/:clientId(\\d+)/edit';

export const PROPOSAL_LIST = '/proposal/';
export const PROPOSAL_SHOW = '/proposal/:proposalId(\\d+)';
export const PROPOSAL_EDIT = PROPOSAL_SHOW + '/edit/';
export const PROPOSAL_NEGOTIATION = PROPOSAL_SHOW + '/negotiation/';
export const PROPOSAL_CREATE = CAMPAIGN_SHOW + '/proposal/create/';
export const PROPOSAL_CREATE_FROM_CAMPAIGN =
  CAMPAIGN_SHOW + '/proposal/create/:category(\\d+)?';

export const FLIGHT_EDIT = PROPOSAL_SHOW + '/flight/:flightId/edit';
export const FLIGHT_CREATE = PROPOSAL_SHOW + '/flight/create';

export const PRODUCT_LIST = '/product';
export const PRODUCT_CREATE = '/product/create';
export const PRODUCT_SHOW = '/product/:productId(\\d+)';
export const PRODUCT_EDIT = PRODUCT_SHOW + '/edit';

export const MARKET_LIST = '/market/';
export const MARKET_SHOW = '/market/:category(\\d+)?';

export const USER_LIST = '/user';
export const USER_CREATE = '/user/create';
export const USER_SHOW = '/user/:userId(\\d+)';
export const USER_EDIT = '/user/:userId(\\d+)/edit';

export const AD_SLOT_LIST = '/adslot';
export const AD_SLOT_CREATE = '/adslot/create';
export const AD_SLOT_SHOW = '/adslot/:adSlotId(\\d+)';
export const AD_SLOT_EDIT = '/adslot/:adSlotId(\\d+)/edit';

export const AD_FORMAT_LIST = '/adformat';
export const AD_FORMAT_CREATE = '/adformat/create';
export const AD_FORMAT_SHOW = '/adformat/:adFormatId(\\d+)';
export const AD_FORMAT_EDIT = '/adformat/:adFormatId(\\d+)/edit';

export const AD_TYPE_LIST = '/adtype';
