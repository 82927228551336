import * as React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelect } from 'components';
import { selectAllClients } from 'store/clients';
import { useFormContext, useController } from 'react-hook-form';

const CampaignClient = ({ ...props }: { [key: string]: any }): JSX.Element => {
  const { control, setValue } = useFormContext();
  const {
    field: { value: agencyValue },
  } = useController({
    name: 'mediaAgency',
    control,
  });

  const {
    field: { value: clientValue },
  } = useController({
    name: 'client',
    control,
  });
  const clients = useAppSelector(selectAllClients);

  const options = React.useMemo(() => {
    if (!agencyValue)
      return clients
        .map(({ id, name }: { id: number; name: string }) => ({
          id,
          name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    return clients
      ?.filter(client => client.mediaAgency === agencyValue)
      .map(({ id, name }: { id: number; name: string }) => ({
        id,
        name,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [clients, agencyValue]);

  React.useEffect(() => {
    const exists = options.find(option => option.id === clientValue);
    if (!exists) setValue('client', null);
  }, [agencyValue]);

  return (
    <ControlledSelect
      name='client'
      label='Klient'
      options={options}
      isFilterable
      showUnfilteredResults
      {...props}
    />
  );
};

CampaignClient.displayName = 'Campaign.client';

export { CampaignClient };
