import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './productExtraFees.consts';
import { ProductExtraFeeParams } from './productExtraFees.types';

export const fetchAllProductExtraFees = createAsyncThunk(
  REDUCER_KEY + '/fetchAll',
  withErrorHandling(async () => {
    const products = await adserver({
      url: '/monthlyextrafees/products/',
      headers: {},
    });
    return products.data;
  }),
);

export const createProductExtraFee = createAsyncThunk(
  REDUCER_KEY + '/createProductExtraFee',
  withErrorHandling(async (values: ProductExtraFeeParams) => {
    const response = await adserver({
      url: '/monthlyextrafees/products/',
      method: 'POST',
      data: values,
      headers: {},
    });
    return response.data;
  }),
);

export const updateProductExtraFee = createAsyncThunk(
  REDUCER_KEY + '/update',
  withErrorHandling(async (data: ProductExtraFeeParams) => {
    const { id } = data;

    const response = await adserver({
      url: `/monthlyextrafees/products/${id}/`,
      method: 'PATCH',
      data: data,
      headers: {},
    });
    return response.data;
  }),
);

export const removeProductExtraFee = createAsyncThunk(
  REDUCER_KEY + '/removeProductExtraFee',
  withErrorHandling(async (productId: number) => {
    console.log('productId', productId);
    await adserver({
      url: `/monthlyextrafees/products/${productId}/`,
      method: 'DELETE',
      headers: {},
    });
    return productId;
  }),
);

export const removeAllProductExtraFees = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});
