import { createSelector } from 'reselect';
import { RootState } from 'store';
import adapter from './adTypeExtraFees.adapter';
import { REDUCER_KEY } from './adTypeExtraFees.consts';
import { AdTypeExtraFeeParams } from './adTypeExtraFees.types';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (
  state: RootState,
  id: number,
): AdTypeExtraFeeParams | null => selectors.selectById(state, id) || null;

export const selectCurrentAdFormatId = (state: RootState): number =>
  state[REDUCER_KEY].current;

export const selectAllAdTypeExtraFees = createSelector(
  state => selectors.selectAll(state),
  extraFees => extraFees || [],
);

export const selectCurrentAdFormat = createSelector(
  selectCurrentAdFormatId,
  selectAllAdTypeExtraFees,
  (currentId: number, adFormats: AdTypeExtraFeeParams[]) =>
    adFormats.find(({ id }: { id: number }) => id === currentId),
);

export function selectAdTypeExtraFee(
  state: RootState,
  adTypeId: string,
): AdTypeExtraFeeParams | undefined {
  const allExtraFees = selectAllAdTypeExtraFees(state);
  return allExtraFees.find(
    ({ adType }: AdTypeExtraFeeParams) => adType === adTypeId,
  );
}
