import { createSlice } from '@reduxjs/toolkit';
import { NO_CURRENT_ENTITY } from 'consts/store';
import { DATA_UNDEFINED, NO_ERRORS } from 'consts/xhr';
import {
  pendingActionHandler,
  readyActionHandler,
  rejectActionHandler,
} from 'helpers/sliceHelpers';
import {
  createProductExtraFee,
  fetchAllProductExtraFees,
  removeProductExtraFee,
  updateProductExtraFee,
} from './productExtraFees.actions';
import adapter from './productExtraFees.adapter';
import { REDUCER_KEY } from './productExtraFees.consts';

const slice = createSlice({
  name: REDUCER_KEY,
  initialState: {
    ...adapter.getInitialState(),
    status: DATA_UNDEFINED,
    errors: NO_ERRORS,
    current: NO_CURRENT_ENTITY,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAllProductExtraFees.fulfilled, (state, action) =>
        adapter.upsertMany(state, action.payload),
      )
      .addCase(createProductExtraFee.fulfilled, (state, action) =>
        adapter.addOne(state, action.payload),
      )
      .addCase(updateProductExtraFee.fulfilled, (state, action) =>
        adapter.upsertOne(state, action.payload),
      )
      .addCase(removeProductExtraFee.fulfilled, (state, action) =>
        adapter.removeOne(state, action.payload),
      )
      .addCase(REDUCER_KEY + '/removeAll', state => adapter.removeAll(state))
      .addMatcher(
        ({ type }) =>
          type.startsWith(REDUCER_KEY) && type.endsWith('fulfilled'),
        readyActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(REDUCER_KEY) && type.endsWith('pending'),
        pendingActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(REDUCER_KEY) && type.endsWith('rejected'),
        rejectActionHandler,
      );
  },
});

export const { reducer } = slice;
